<template>
  <b-container class="body-container">
    <h1>Donations</h1>

    <div class="well well-lg">
      <p>We are actively working to expand our services to include such things as name removal for the deceased,
        protection against unauthorized baptisms for the dead, and other
        services you, our clients, have been requesting!</p>
    </div>

    <p>Thank you for your support. 100% of your donation will go to operational costs of processing resignations,
      running the website, and expanding our services.</p>
    <p>We are a 501(c)(3) tax exempt charity, which means that your donation may be tax deductible. Please check with
      your tax professional.</p>

    <h2 class="mt-10">How to donate to us</h2>
    <div class="d-flex fill-height">

      <v-card class="mr-2 mb-2">
        <v-card-title>
          Checks
        </v-card-title>
        <v-card-text>
          Send checks payable to <u>Quitmormon.com</u> to:
          <p>
            Quitmormon.com <br>
            6975 Union Park Ave Suite 600 <br>
            Cottonwood Heights, UT 84047 <br>
          </p>
        </v-card-text>
      </v-card>

      <v-card class="mr-2 mb-2">
        <v-card-title>
          PayPal
        </v-card-title>
        <v-card-text>
          Send your donation to nauglelaw@gmail.com
        </v-card-text>
      </v-card>

      <v-card class="mr-2 mb-2">
        <v-card-title>
          Credit Card
        </v-card-title>
        <v-card-text>
          <form action="https://Simplecheckout.authorize.net/payment/CatalogPayment.aspx"
                method="post"
                name="PrePage">
            <input name="LinkId"
                   type="hidden"
                   value="0584f3e1-ecbf-4691-b66b-0530e5c61cb3">
            <v-btn type="submit">
              Pay via credit card
            </v-btn>
          </form>

        </v-card-text>
      </v-card>
    </div>


    <!--<p>
      Please like and rate us on &nbsp;<a href="https://www.facebook.com/OfficialQuitMormon">Facebook</a>&nbsp; and follow us on &nbsp;<a href="https://twitter.com/QuitMormon">Twitter!</a>
    </p>-->

    <h2 class="mt-10">Please also consider</h2>

    <p>Please also consider supporting the following charities:</p>
    <ul>
      <li>
        <a href="https://action.aclu.org/secure/support-aclu-utah"
           target="_blank">The ACLU of Utah</a>
      </li>
      <li>
        <a href="https://www.equalityutah.org/donate"
           target="_blank">Equality Utah</a>
      </li>
      <li>
        <a href="https://utahpridecenter.org/"
           target="_blank">Utah Pride Center</a>
      </li>
      <li>
        <a href="https://shop.hrc.org/"
           target="_blank">Human Rights Campaign</a>
      </li>
    </ul>

  </b-container>
</template>

<script>
import donateImage from '@/assets/Donate.png';

export default {
  name: 'Donate',
  computed: {
    donateImage() {
      return donateImage;
    },
  },
};
</script>

<style scoped>

</style>
